"use client";

/**
 * Third-party libraries.
 */
import { Skeleton } from "antd";
import dayjs from "dayjs";
import { useMemo, useState } from "react";

/**
 * Project components.
 */
import { UserAvatar } from "@/components/client/avatar";
import { UserAvailabilityStatus } from "@/components/client/graphql";
import { ChevronDown, ChevronRight } from "@/components/client/images";
import { TimeUtility } from "@/components/common/time";
import {
  formatDate,
  formatDuration,
} from "@/components/common/time/utilities/time-utility";
import { StringUtility } from "@/components/common/utilities";
import { useInterval } from "../hooks/use-interval";

// =============================================================================
// Agent
// =============================================================================

/**
 * Properties of the Agent component.
 */
type AgentProps = {
  /**
   * Name of the Agent.
   */
  name: string;
  /**
   * Availability status of the agent.
   */
  status: UserAvailabilityStatus;
  /**
   * Date and time the status was updated.
   */
  statusDateTime: Date;
};

/**
 * Displays an agent with their availability status and time in status.
 */
const Agent = ({ name, status, statusDateTime }: AgentProps) => {
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const [timeInStatus, setTimeInStatus] = useState<string>(
    TimeUtility.formatDuration({
      from: statusDateTime,
      to: new Date(),
      format: "numeric",
    }),
  );

  // ===========================================================================
  // ===========================================================================
  // Variables
  // ===========================================================================
  // ===========================================================================

  /**
   * User availability status display.
   * Converts the underlying status to a human-readable format.
   */
  const userAvailabilityStatus = useMemo(() => {
    switch (status) {
      case UserAvailabilityStatus.Available:
        return "Available";
      case UserAvailabilityStatus.Break:
        return "Break";
      case UserAvailabilityStatus.Busy:
        return "Busy";
      case UserAvailabilityStatus.Offline:
        return "Offline";
      case UserAvailabilityStatus.OnACall:
        return "On Call";
      case UserAvailabilityStatus.Ringing:
        return "Ringing";
      case UserAvailabilityStatus.WrappingUp:
        return "Wrapping Up";
      default:
        return "Unknown";
    }
  }, [status]);

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  useInterval(() => {
    const today = dayjs().startOf("day").toDate();
    const yesterday = dayjs().subtract(1, "day").startOf("day").toDate();

    // 1. Older dates e.g. "Since MM/DD/YYYY, 10:34AM" (When: current date < yesterday)
    if (statusDateTime < yesterday) {
      setTimeInStatus(
        `Since ${formatDate(statusDateTime, { format: "DD MMM" })}`,
      );
      return;
    }

    // 2. Since Yesterday (When: yesterday < current date < today)
    if (statusDateTime < today && statusDateTime > yesterday) {
      setTimeInStatus(`Since Yesterday`);
      return;
    }

    // 3. Everything else
    setTimeInStatus(
      formatDuration({
        from: statusDateTime,
        to: new Date(),
        format: "numeric",
      }),
    );
  }, 1000);

  return (
    <div className="flex animate-slide-left items-center gap-2">
      <UserAvatar
        status={status}
        initials={
          StringUtility.getInitials({
            input: name,
            maxLength: 2,
          }) ?? ""
        }
      />
      <div className="inline-flex flex-1 flex-col items-start justify-center gap-1">
        <div className="self-stretch text-sm font-semibold leading-[17.50px] text-tpl-navy">
          {name}
        </div>
        <div className="self-stretch text-xs font-semibold leading-none text-gray-400">
          {userAvailabilityStatus} | {timeInStatus}
        </div>
      </div>
    </div>
  );
};

// =============================================================================
// Agent List Group
// =============================================================================

/**
 * Properties of the AgentListGroup component.
 */
type AgentListGroupProps = {
  /**
   * List of agents.
   */
  agents: AgentProps[];
  /**
   * Default state of the group.
   */
  defaultExpanded?: boolean;
  /**
   * Group label.
   */
  label: string;
  /**
   * Loading state.
   * Displays a skeleton when true.
   */
  loading?: boolean;
};

/**
 * Displays a group of agents.
 * This group has a group label on top of the list.
 */
function AgentListGroup({
  agents,
  defaultExpanded = true,
  label,
  loading,
}: AgentListGroupProps) {
  const [expand, setExpand] = useState<boolean>(defaultExpanded);

  return (
    <div className="flex h-full w-full flex-col gap-3 leading-7">
      <div
        className="sticky top-0 z-10 flex w-full cursor-pointer items-center justify-between bg-white"
        onClick={() => {
          setExpand(!expand);
        }}
      >
        <h4 className="!m-0 text-gray-400">{label}</h4>
        {expand ? (
          <ChevronDown className="h-4 w-4 text-gray-400" />
        ) : (
          <ChevronRight className="h-4 w-4 text-gray-400" />
        )}
      </div>
      {loading && (
        <div className="flex gap-2">
          <div>
            <Skeleton.Avatar size="large" active />
          </div>
          <div className="flex flex-col">
            <Skeleton.Input size="small" active />
            <Skeleton.Input size="small" active />
          </div>
        </div>
      )}
      {!loading && (
        <div
          className={`flex flex-col gap-3 overflow-hidden ${
            expand && agents?.length ? "" : "hidden"
          }`}
        >
          {agents.map((agent) => (
            <Agent
              key={agent.name}
              name={agent.name}
              status={agent.status}
              statusDateTime={agent.statusDateTime}
            />
          ))}
        </div>
      )}
    </div>
  );
}

// =============================================================================
// Agent List
// =============================================================================

/**
 * Properties of the AgentList component.
 */
export type AgentListProps = {
  /**
   * The agents to display.
   */
  agents: AgentProps[];
  /**
   * Indicates that the list is loading.
   */
  loading?: boolean;
};

/**
 * Displays an agent list with their availability status.
 *
 * This displays two groups of agents, one (1) group for online agents, and
 * another group for offline agents.
 */
export function AgentList({ agents, loading }: AgentListProps) {
  /**
   * Agents who are not in "Offline" status.
   */
  const onlineAgents = useMemo(
    () =>
      agents?.filter(
        (agent) => agent.status !== UserAvailabilityStatus.Offline,
      ),
    [agents],
  );

  /**
   * Agents who are in "Offline" status.
   */
  const offlineAgents = useMemo(
    () =>
      agents?.filter(
        (agent) => agent.status === UserAvailabilityStatus.Offline,
      ),
    [agents],
  );

  return (
    <div className="flex w-full flex-col gap-3">
      <AgentListGroup
        agents={onlineAgents}
        label={`Active Agents (${onlineAgents.length}/${agents?.length})`}
        loading={loading}
      />
      <AgentListGroup
        agents={offlineAgents}
        label={`Offline Agents (${offlineAgents.length}/${agents?.length})`}
        loading={loading}
        defaultExpanded={false}
      />
    </div>
  );
}
